import { queryVendorList, listByPlatform } from '@/api/scm-api'
import { queryStyleList, queryTterraceList, basebu, queryColorList, queryBaseSizeList } from '@/api/listSelection'

export default {
  vendorList: {
    api: queryVendorList,
    configuration: {
      key: 'id',
      label: 'vendorName',
      value: 'id'
    }
  },
  platformList: {
    api: queryTterraceList,
    configuration: {
      key: 'id',
      label: 'platformName',
      value: 'id'
    }
  },
  siteList: {
    api: listByPlatform,
    configuration: {
      key: 'id',
      label: 'siteName',
      value: 'siteCode'
    }
  },
  styleList: {
    api: queryStyleList,
    configuration: {
      key: 'id',
      label: 'styleName',
      value: 'styleName'
    }
  },
  colorList: {
    api: queryColorList,
    configuration: {
      key: 'id',
      label: 'colorName',
      value: 'id'
    }
  },
  sizeList: {
    api: queryBaseSizeList,
    configuration: {
      key: 'id',
      label: 'size',
      value: 'id'
    }
  },
  buList: {
    api: basebu,
    configuration: {
      key: 'id',
      label: 'buName',
      value: 'buName'
    }
  }
}
